import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, message, Modal, Select, Upload } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useBaseUrl } from "hooks/base-url-hook/useBaseUrl";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React, { useEffect, useState } from "react";

export default function AddReportingSarpras({ open, setOpen, refetch }) {
  const [foundationId, setFoundationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [EmployeeId, setEmployeeId] = useState("");

  /// hardcode permission
  const roles = decryptCookies("role").split(",");
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const { refetch: refetchBaseUrl } = useBaseUrl();
  const { data: dataFoundation } = useFoundationList();
  const { data: dataEmployee, refetch: refetchEmployee } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
    "",
    ""
  );

  const getEmployeeId = async () => {
    try {
      const { data } = await refetchBaseUrl();
      setEmployeeId(data?.data[0]?.employee_id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployeeId();
    // eslint-disable-next-line
  }, []);

  // upload image
  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    form.setFieldValue("file", null);
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChange,
  };

  // end upload image

  useEffect(() => {
    refetchEmployee();
    // eslint-disable-next-line
  }, [foundationId]);

  const onCancel = () => {
    form.resetFields();
    setFileList([]);
    setFoundationId("");
    setOpen();
  };

  const onSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }
    const values = await form.validateFields();
    if (fileList.length > 0) {
      values.image = values.image.file;
    }
    if (!roles.includes("super_admin")) {
      values.employee_id = EmployeeId;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        REACT_APP_HOST_API + `/inventories/reportings`,
        { ...values },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      refetch();
      onCancel();
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <div>{error?.response?.data?.message}</div>,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="top-10"
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        {roles.includes("super_admin") && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              onChange={(value) => {
                setFoundationId(value);
                form.resetFields(["employee_id"]);
              }}
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Nama Barang"
          name="name"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Deskripsi Laporan"
          name="description"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          tooltip="cth: kelas XI lantai 1"
          label="Lokasi"
          name="location"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>

        {roles.includes("super_admin") && (
          <Form.Item
            label="Pelapor"
            name="employee_id"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataEmployee?.data.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="image"
          label="Gambar"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="image"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
                {fileList.length === 0 ? "" : ""}
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
