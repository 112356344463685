import { Form, Input, message, Modal, Skeleton } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { renderModalError } from "components/UI/ErrorModal";
import { useEmployeeDetail } from "hooks/employee-hook/useEmployeeDetail";
import { useEffect, useState } from "react";

const UpdateRfid = ({ id, show, onUpdateRfid, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});

  const { REACT_APP_HOST_API } = process.env;

  const { data, isLoading: skeleton, refetch } = useEmployeeDetail(id, false);

  useEffect(() => {
    if (show) refetch();
    if (data) form.setFieldValue("rfid", data.data[0].rfid);

    return () => setNewData({});
  }, [form, data, show, refetch]);



  const handleSubmit = async () => {
    await form.validateFields();

    if (Object.keys(newData).length === 0) {
      renderModalError("Error", "Tidak ada yang berubah");
      return;
    }

    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (element === "") newData[key] = null;
      }
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        REACT_APP_HOST_API + "/employee/" + id,
        { rfid: newData.rfid },
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );

      message.success(data.message);
      onUpdateRfid();
    } catch (error) {
      let msg = error.response?.data?.message || error.message;
      renderModalError("Error edit RFID", msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="RFID"
      okText="Simpan"
      cancelText="Batal"
      open={show}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={{ loading }}
    >
      {skeleton && <Skeleton active />}

      {!skeleton && (
        <Form form={form} layout="vertical">
          <Form.Item
            name="rfid"
            rules={[
              {
                pattern: new RegExp("^([0-9]*)$"),
                message: "Masukkan hanya Angka",
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              onChange={({ target: { value } }) => (newData.rfid = value)}
              allowClear
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default UpdateRfid;
