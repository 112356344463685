import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import { DeleteApi } from "api/DeleteApi";
import { ActivityContext } from "context/ActivityContext";
import dayjs from "dayjs";
import { useActivityDetail } from "hooks/activity-hook/useActivityDetail";
import { useSubActivityPagination } from "hooks/activity-hook/useSubActivityPagination";
import { usePermission } from "hooks/usePermissions";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const disabled = { color: "#ccc", cursor: "not-allowed" };

const SubActivity = () => {
  // custom date variables
  const newDate = new Date();
  const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1); // Awal bulan
  const lastDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0); // Akhir bulan  
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState("");
  const [dataTable, setDataTable] = useState({ per_page: 10, current_page: 1 });
  const [date, setDate] = useState({
    from: moment(firstDate).format("YYYY-MM-DD"),
    to: moment(lastDate).format("YYYY-MM-DD"),
  });

  const { fetchSession, fetchActivity, setFetchSession } =
    useContext(ActivityContext);

  const { activity_id } = useParams();
  const navigate = useNavigate();

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuActivity");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddSubActivity");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditSubActivity");
  const btnDetail = dataPermission?.find(
    (x) => x.id === "btnDetailSubActivity"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteSubActivity"
  );
  const btnRecap = dataPermission?.find((x) => x.id === "btnRecapSubActivity");

  const { data: dataDetail, refetch: fetchDetail } = useActivityDetail(
    activity_id,
    false
  );

  const { data, isFetching, refetch } = useSubActivityPagination({
    activity_id,
    keyword,
    dataTable,
    date,
  });

  useEffect(() => {
    refetch();
    fetchDetail();

    return () => setFetchSession(false);
  }, [refetch, fetchDetail, fetchActivity, fetchSession, setFetchSession]);

  const dataSource = data?.data?.data?.map((x, i) => {
    return {
      ...x,
      no: i + 1,
      key: x.id,
      date: moment(x.date)?.format("YYYY-MM-DD"),
    };
  });

  const columns = [
    { title: "No", dataIndex: "no", align: "center", width: 50, fixed: "left" },
    {
      title: "Nama",
      dataIndex: "name",
      fixed: "left",
      width: window.innerWidth > 700 ? "" : 150,
    },
    { title: "Tanggal", dataIndex: "date" },
    {
      title: "Jumlah Kehadiran",
      dataIndex: "presence_count",
      align: "center",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus?"
            okText="Hapus"
            cancelText="Batal"
            onConfirm={() =>
              DeleteApi({ url: "/sub-activities/", dataId: id, refetch })
            }
            disabled={btnDelete?.type === "disabled"}
          >
            <Tag
              color="magenta"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") return;
              else navigate(`${id}/edit`);
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="blue"
            hidden={!btnDetail}
            style={
              btnDetail?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnDetail?.type === "disabled") return;
              else navigate(id);
            }}
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item, ellipsis: true }));

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <div>Tanggal Awal</div>
          <DatePicker
            value={dayjs(date.from)}
            style={{ width: "100%" }}
            onChange={(value) => {
              setDate((curr) => ({
                ...curr,
                from:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(firstDate).format("YYYY-MM-DD"),
              }));
            }}
            placeholder="Awal"
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div>Tanggal Akhir</div>
          <DatePicker
            value={dayjs(date.to)}
            onChange={(value) =>
              setDate((curr) => ({
                ...curr,
                to:
                  value !== null
                    ? value.format("YYYY-MM-DD")
                    : moment(lastDate).format("YYYY-MM-DD"),
              }))
            }
            placeholder="Akhir"
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <Button style={{ width: "100%" }} type="primary">
          Close
        </Button>
      ),
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "3") setOpen(false);
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar {dataDetail?.data?.name}</h1>
        <Space>
          <Button
            type="primary"
            onClick={() => navigate("recap")}
            disabled={btnRecap?.type === "disabled"}
            hidden={!btnRecap}
          >
            Rekap
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (btnAdd?.type === "disabled") return;
              else navigate("create");
            }}
            disabled={btnAdd?.type === "disabled"}
            hidden={!btnAdd}
          >
            Tambah Sesi
          </Button>
        </Space>
      </div>
      <div
        style={{
          maxWidth: 400,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 10,
        }}
      >
        <Input
          allowClear
          value={keyword}
          onChange={({ target: { value } }) => setKeyword(value)}
          placeholder="Cari nama.."
        />
        <Dropdown
          menu={{ items, onClick: handleMenuClick }}
          onOpenChange={handleOpenChange}
          open={open}
          trigger={["click"]}
        >
          <Button icon={<FilterOutlined />}>Filter Tanggal</Button>
        </Dropdown>
      </div>
      <Table
        size="small"
        columns={tableColumns}
        dataSource={dataSource}
        loading={isFetching}
        pagination={pagination}
        scroll={{ x: 800, y: "35vh" }}
      />
    </>
  );
};

export default SubActivity;
