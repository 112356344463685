import { Form, Input, Modal, Radio } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { editValidation } from "components/Helper/editValidation";
import React, { useEffect, useState } from "react";

export default function EditRoom({ open, setOpen, record, refetch }) {
  const [floor, setFloor] = useState(null);
  const [newData, setNewData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const defaultFloor = ["1", "2", "3"];
  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: record?.name,
        allow_events:
          record?.allow_events.toLowerCase() === "bisa" ? true : false,
        floor: defaultFloor.includes(record?.floor) ? record.floor : "other",
        floor_other: !defaultFloor.includes(record?.floor) ? record.floor : "",
        description: record?.description,
      });
      setFloor(form.getFieldValue("floor"));
    }
    return () => setNewData({});
    // eslint-disable-next-line
  }, [record, open]);

  const onCancel = () => {
    form.resetFields();
    setFloor(null);
    setOpen();
  };

  const onSubmit = async () => {
    await form.validateFields();

    const isValid = editValidation({ newData });
    if (!isValid) return;

    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_HOST_API}/inventories/rooms/${record?.id}`,
        newData,
        { headers: { Authorization: "Bearer " + decryptCookies("token") } }
      );
      refetch();
      onCancel();
    } catch (error) {
      Modal.error({
        title: "Gagal Mengubah",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Nama Ruangan" name="name">
          <Input
            onChange={({ target: { value } }) => (newData["name"] = value)}
          />
        </Form.Item>

        <Form.Item label="Lantai" name="floor">
          <Radio.Group
            onChange={(e) => {
              setFloor(e.target.value);
              newData["floor"] = e.target.value;
            }}
          >
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="other">Other</Radio>
          </Radio.Group>
        </Form.Item>

        {floor === "other" && (
          <Form.Item name="floor_other" rules={[{ required: true }]}>
            <Input
              autoFocus
              onChange={({ target: { value } }) => (newData["floor"] = value)}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Dapat Digunakan oleh Umum (Untuk Acara/Kegiatan)"
          name="allow_events"
        >
          <Radio.Group
            onChange={({ target: { value } }) =>
              (newData["allow_events"] = value)
            }
          >
            <Radio value={true}>Bisa</Radio>
            <Radio value={false}>Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Deskripsi" name="description">
          <Input.TextArea
            rows={3}
            onChange={({ target: { value } }) =>
              (newData["description"] = value)
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
