import { Badge, Calendar, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export default function CalendarRoomBooking({ data }) {
  const [dataRoomBooking, setDataRoomBooking] = useState([]);

  const isMobile = useMediaQuery({
    maxWidth: 768,
  });

  useEffect(() => {
    if (data) {
      setDataRoomBooking(data);
    }
  }, [data]);

  const showModal = (item, date) => {
    const convertDate = dayjs(date).format("dddd, DD MMMM");

    Modal.info({
      title: convertDate,
      content: (
        <table>
          <tbody>
            <tr>
              <td>Ruangan</td>
              <td>
                : {item?.room?.name ? item?.room?.name : item?.room_string}
              </td>
            </tr>

            <tr>
              <td>Peminjam</td>
              <td>: {item?.employee?.name}</td>
            </tr>

            <tr>
              <td>Barang</td>
              <td>: {item?.assets.join(", ")}</td>
            </tr>

            <tr>
              <td>Waktu</td>
              <td>
                : {dayjs(item?.start_time, "HH:mm:ss").format("HH:mm")} -{" "}
                {dayjs(item?.end_time, "HH:mm:ss").format("HH:mm")}
              </td>
            </tr>

            <tr>
              <td>Deskripsi Keperluan</td>
              <td>: {item?.description}</td>
            </tr>
          </tbody>
        </table>
      ),
    });
  };

  const showModalMobile = (item, date) => {
    const convertDate = dayjs(date).format("dddd, DD MMMM");

    Modal.info({
      style: { top: "10px" },
      title: convertDate,
      content: (
        <div className="max-h-[530px] overflow-y-auto flex flex-col gap-2">
          {item?.map((item, index) => (
            <table key={index} className="w-full border border-gray-200">
              <tbody>
                <tr>
                  <td>Ruangan</td>
                  <td>
                    : {item?.room?.name ? item?.room?.name : item?.room_string}
                  </td>
                </tr>

                <tr>
                  <td>Peminjam</td>
                  <td>: {item?.employee?.name}</td>
                </tr>

                <tr>
                  <td>Barang</td>
                  <td>: {item?.assets.join(", ")}</td>
                </tr>

                <tr>
                  <td>Waktu</td>
                  <td>
                    : {dayjs(item?.start_time, "HH:mm:ss").format("HH:mm")} -{" "}
                    {dayjs(item?.end_time, "HH:mm:ss").format("HH:mm")}
                  </td>
                </tr>

                <tr>
                  <td>Deskripsi Keperluan</td>
                  <td>: {item?.description}</td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      ),
    });
  };

  const getListData = (value) => {
    const listData = dataRoomBooking?.data?.filter((event) => {
      return event.dates.some((date) => {
        const eventDate = new Date(date);
        return (
          eventDate.getDate() === value.date() &&
          eventDate.getMonth() === value.month() &&
          eventDate.getFullYear() === value.year()
        );
      });
    });

    return listData?.map((event) => ({
      ...event,
      content: `${dayjs(event?.start_time, "HH:mm:ss").format("HH:mm")} - ${
        event?.room?.name ? event?.room?.name : event?.room_string
      } - ${event?.employee?.name}`,
    }));
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return isMobile ? (
      <ul className="events">
        {listData?.length > 0 && (
          <li
            onClick={() => showModalMobile(listData, value)}
            className={` rounded-lg bg-green-100`}
          >
            <Badge status="processing" />
          </li>
        )}
      </ul>
    ) : (
      <div className="flex flex-col">
        {listData?.map((item, index) => (
          <Badge
            onClick={() => {
              showModal(item, value);
            }}
            key={index}
            color="blue"
            text={item.content}
          />
        ))}
      </div>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  // Custom handler untuk menampilkan dropdown bulan dan tahun
  const headerRender = ({ value, onChange }) => {
    const month = value.month(); // Mengambil bulan saat ini
    const year = value.year(); // Mengambil tahun saat ini

    // Tahun dari 5 tahun sebelum sekarang hingga 5 tahun sesudah
    const years = Array.from({ length: 11 }, (_, i) => year - 5 + i);

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}>
        {/* Dropdown untuk pemilihan bulan */}
        <div>
          <Select
            value={month}
            onChange={(e) => {
              const newMonth = parseInt(e, 10);
              const newValue = value.clone().month(newMonth);
              onChange(newValue);
            }}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <Select.Option key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </Select.Option>
            ))}
          </Select>
        </div>

        {/* Dropdown untuk pemilihan tahun */}
        <div style={{ marginLeft: 10 }}>
          <Select
            value={year}
            onChange={(e) => {
              const newYear = parseInt(e, 10);
              const newValue = value.clone().year(newYear);
              onChange(newValue);
            }}
          >
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };
  return (
    <div className={isMobile ? "" : "mt-8 overflow-auto border rounded-md"}>
      <div className={isMobile ? "" : "min-w-[900px]"}>
        <Calendar
          cellRender={cellRender}
          headerRender={headerRender}
          fullscreen={!isMobile}
        />
      </div>
    </div>
  );
}
