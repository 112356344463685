import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  TimePicker,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useRoomList } from "hooks/sarpras-hook/room/useRoomList";
import React, { useEffect, useRef, useState } from "react";

export default function AddRoomBooking({ open, setOpen, refetch }) {
  const [foundationId, setFoundationId] = useState("");
  const [assets, setAssets] = useState([]);
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const [items, setItems] = useState([]);
  const inputRef = useRef(null);
  const [roomName, setRoomName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const roles = decryptCookies("role").split(",");
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataFoundation } = useFoundationList();
  const { data: dataRoom } = useRoomList(foundationId, "");
  const { data: dataEmployee, refetch: refetchEmployee } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
    "",
    ""
  );

  const onCancel = () => {
    form.resetFields();
    setFoundationId("");
    setIsEmptyName(false);
    setAssets([]);
    setItems([]);
    setOpen();
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    const payload = {
      ...values,
      dates: values.dates.map((x) => dayjs(x).format("YYYY-MM-DD")),
      start_time: dayjs(values.times[0]).format("HH:mm:ss"),
      end_time: dayjs(values.times[1]).format("HH:mm:ss"),
      assets: assets.includes("other")
        ? [...values.assets, values.assets_other].filter(
            (item) => item !== "other"
          )
        : values.assets,
    };

    try {
      setLoading(true);
      await axios.post(
        `${REACT_APP_HOST_API}/inventories/room-bookings`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      refetch();
      onCancel();
      message.success("Berhasil Menyimpan Data");
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <div>{error?.response?.data?.message}</div>,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetchEmployee();
    // eslint-disable-next-line
  }, [foundationId]);

  useEffect(() => {
    if (open && dataRoom) {
      setItems(dataRoom.data);
    }
  }, [open, dataRoom]);

  const addItem = () => {
    if (!roomName) {
      setIsEmptyName(true);
      return;
    }
    try {
      form.setFieldValue("room", roomName);
      setIsEmptyName(false);
      message.success("Berhasil Menambah Ruangan");
      setRoomName("");
      setDropdownOpen(false);
    } catch (error) {
      alert(error);
    }
  };
  const handleTimeChange = (value) => {
    if (isOpenTime) {
      const timeConvert = value.map((item) =>
        dayjs(item, "HH:mm").format("HH:mm:ss")
      );
      setTimeValue(timeConvert);
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      className="top-5"
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        {roles.includes("super_admin") && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              onChange={(value) => {
                setFoundationId(value);
                form.resetFields(["employee_id"]);
                form.resetFields(["room"]);
              }}
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {roles.includes("super_admin") &&
          !roles.includes("admin_foundation") && (
            <Form.Item
              label="Peminjam"
              name="employee_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {dataEmployee?.data.map((data) => (
                  <Select.Option key={data.id} value={data.id}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

        <Form.Item label="Ruangan" name="room" rules={[{ required: true }]}>
          <Select
            open={dropdownOpen}
            onDropdownVisibleChange={(open) => setDropdownOpen(open)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="pilih ruangan..."
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                    position: "relative",
                  }}
                >
                  <div className="w-[180px]">
                    <Input
                      value={roomName}
                      placeholder="Masukan Nama Ruangan..."
                      ref={inputRef}
                      onChange={(e) => setRoomName(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                      className={isEmptyName && "border border-[#ff4d4f]"}
                    />
                    {isEmptyName && (
                      <span className="text-[#ff4d4f] text-sm">
                        Please enter a room name
                      </span>
                    )}
                  </div>

                  <Button
                    style={{ position: "absolute", top: 0 }}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Tambah Ruangan
                  </Button>
                </Space>
              </>
            )}
            options={items?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
        </Form.Item>

        <Form.Item label="Barang" name="assets" rules={[{ required: true }]}>
          <Checkbox.Group onChange={setAssets}>
            <Checkbox value="Laptop">Laptop</Checkbox>
            <Checkbox value="Proyektor">Proyektor</Checkbox>
            <Checkbox value="Kursi">Kursi</Checkbox>
            <Checkbox value="Meja">Meja</Checkbox>
            <Checkbox value="Rol Kabel">Rol Kabel</Checkbox>
            <Checkbox value="other">Other</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        {assets.includes("other") && (
          <Form.Item name="assets_other" rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
        )}

        <Form.Item
          label="Jumlah Peserta"
          name="participant_count"
          rules={[{ required: true }]}
        >
          <InputNumber min={0} className="w-full" />
        </Form.Item>

        <div className="grid md:gap-5 md:grid-cols-2">
          <Form.Item
            label="Tanggal (Daftar Tanggal)"
            name="dates"
            rules={[{ required: true }]}
          >
            <DatePicker multiple maxTagCount="responsive" />
          </Form.Item>

          <Form.Item
            label="Waktu (Dari-Sampai)"
            name="times"
            rules={[{ required: true }]}
          >
            <TimePicker.RangePicker
              format="HH:mm"
              value={timeValue}
              onCalendarChange={handleTimeChange}
              onOpenChange={setIsOpenTime}
            />
          </Form.Item>
        </div>

        <Form.Item
          label="Deskripsi Keperluan"
          name="description"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
