import { Modal } from "antd";
import dayjs from "dayjs";
import React from "react";

export default function DetailRoomBooking({ open, setOpen, item }) {
  return (
    <Modal open={open} onCancel={setOpen} footer={false}>
      <table>
        <tbody>
          <tr>
            <td>Ruangan</td>
            <td>: {item?.room?.name ? item?.room?.name : item?.room_string}</td>
          </tr>

          <tr>
            <td>Peminjam</td>
            <td>: {item?.employee?.name}</td>
          </tr>

          <tr>
            <td>Barang</td>
            <td>: {item?.assets?.join(", ")}</td>
          </tr>

          <tr>
            <td>Tanggal</td>
            <td>: {item?.dates?.join(", ")}</td>
          </tr>

          <tr>
            <td>Waktu</td>
            <td>
              : {dayjs(item?.start_time, "HH:mm:ss").format("HH:mm")} -{" "}
              {dayjs(item?.end_time, "HH:mm:ss").format("HH:mm")}
            </td>
          </tr>

          <tr>
            <td>Deskripsi Keperluan</td>
            <td>: {item?.description}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}
