import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useReportingSarprasPagination = (
  dataTable,
  keyword = "",
  foundationId = "",
  status = "",
  fromDate = "",
  toDate = ""
) => {
  return useQuery(
    [
      "get-reporting-room-pagination",
      dataTable.per_page,
      dataTable.current_page,
      keyword,
      foundationId,
      status,
      fromDate,
      toDate,
    ],
    () =>
      GetPagination(
        `/inventories/reportings?page=${dataTable.current_page}&limit=${dataTable.per_page}&mode=page&foundation_id=${foundationId}&keyword=${keyword}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`
      )
  );
};
