import { message } from "antd";

// example: const isValid = editValidation({ newData });
// if (!isValid) return;

export const editValidation = ({ newData }) => {
  if (Object.keys(newData).length === 0) {
    message.info("Tidak ada perubahan data");
    return false;
  }

  for (const key in newData) {
    if (Object.hasOwnProperty.call(newData, key)) {
      const element = newData[key];
      if (element === "") newData[key] = null;
    }
  }
  return true;
};
