import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import { DeleteApi } from "api/DeleteApi";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import { useRoomPagination } from "hooks/sarpras-hook/room/useRoomPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import EditRoom from "../edit/EditRoom";
import AddRoom from "../create/AddRoom";
import useResetPage from "components/Helper/useResetPage";

export default function Room() {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [record, setRecord] = useState({});
  const [keyword, setKeyword] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");

  const { data, refetch } = useRoomPagination(dataTable, keyword, foundationId);

  // fetch permission
  const { data: dataPermission } = usePermission("mdlInventory", "mnuRoom");

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddRoom");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditRoom");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteRoom");

  const handleShowEdit = () => setShowEdit(!showEdit);
  const handleShowAdd = () => setShowAdd(!showAdd);

  const disabled = { cursor: "not-allowed", color: "#ccc" };
  const columns = [
    {
      title: "Ruangan",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Lantai",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Dapat Digunakan oleh Umum",
      dataIndex: "allow_events",
      key: "allow_events",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      visible: btnEdit || btnDelete ? true : false,
      render: (id, data) => {
        return (
          <>
            <Popconfirm
              disabled={btnDelete?.type === "disabled"}
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: "/inventories/rooms/",
                  dataId: id,
                  refetch,
                })
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>
            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  setShowEdit(true);
                  setRecord(data);
                }
              }}
            >
              Ubah
            </Tag>
          </>
        );
      },
    },
  ].filter((item) => item.visible !== false);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
        allow_events: data.allow_events ? "Bisa" : "Tidak",
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  useResetPage(setDataTable, [keyword, foundationId]);

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Ruangan</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          onClick={btnAdd?.type === "disabled" ? null : handleShowAdd}
        >
          Tambah
        </Button>
      </div>

      <div className="grid gap-2 sm:grid-cols-2 md:w-1/2">
        <Tooltip title="Cari Ruangan">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Cari Ruangan..."
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        size="small"
      />

      <EditRoom
        open={showEdit}
        setOpen={handleShowEdit}
        record={record}
        refetch={refetch}
      />
      <AddRoom open={showAdd} setOpen={handleShowAdd} refetch={refetch} />
    </div>
  );
}
