import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useDivisionList = (unitId = "") => {
  return useQuery(
    ["get-division-list", unitId || ""],
    () => GetList(`/division-list?${unitId ? `unitId=${unitId}` : ""}`),
    {
      enabled: true,
    }
  );
};
