import { Form, Input, message, Modal, Radio, Select } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React, { useState } from "react";

export default function AddRoom({ open, setOpen, refetch }) {
  const [floor, setFloor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const roles = decryptCookies("role").split(",");
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataFoundation } = useFoundationList();

  const onCancel = () => {
    form.resetFields();
    setFloor(null);
    setOpen();
  };

  const onSubmit = async () => {
    const value = await form.validateFields();
    if (floor === "other") {
      value.floor = value.floor_other;
    }

    setIsLoading(true);
    try {
      await axios.post(`${REACT_APP_HOST_API}/inventories/rooms`, value, {
        headers: {
          Authorization: "Bearer " + decryptCookies("token"),
        },
      });
      refetch();
      onCancel();
      message.success("Berhasil menambahkan ruang");
    } catch (error) {
      Modal.error({
        title: "Gagal menambahkan",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        {roles.includes("super_admin") && (
          <Form.Item
            label="Yayasan"
            name="foundation_id"
            rules={[{ required: true, message: "Harap diisi" }]}
          >
            <Select
              placeholder="Pilih Yayasan"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Nama Ruangan"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Lantai" name="floor" rules={[{ required: true }]}>
          <Radio.Group onChange={(e) => setFloor(e.target.value)}>
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="other">Other</Radio>
          </Radio.Group>
        </Form.Item>

        {floor === "other" && (
          <Form.Item name="floor_other" rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
        )}

        <Form.Item
          label="Dapat Digunakan oleh Umum (Untuk Acara/Kegiatan)"
          name="allow_events"
          rules={[{ required: true }]}
          initialValue={true}
        >
          <Radio.Group>
            <Radio value={true}>Bisa</Radio>
            <Radio value={false}>Tidak</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Deskripsi" name="description">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
