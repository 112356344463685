import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  TimePicker,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { editValidation } from "components/Helper/editValidation";
import dayjs from "dayjs";
import { useRoomList } from "hooks/sarpras-hook/room/useRoomList";
import React, { useEffect, useRef, useState } from "react";

export default function EditRoomBooking({ open, setOpen, refetch, item }) {
  const [foundationId, setFoundationId] = useState("");
  const [assets, setAssets] = useState([]);
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const [isOpenTime, setIsOpenTime] = useState(false);
  const [newData, setNewData] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;

  const { data: dataRoom, refetch: refetchRoom } = useRoomList(
    foundationId,
    ""
  );

  const defaultAssets = ["Laptop", "Proyektor", "Kursi", "Meja", "Rol Kabel"];

  useEffect(() => {
    if (open) {
      // Buat salinan assets dari item
      const assetsResult = [...item.assets];
      // Periksa apakah ada asset yang bukan bagian dari defaultAssets
      if (item?.assets.some((asset) => !defaultAssets.includes(asset))) {
        if (!assetsResult.includes("other")) {
          assetsResult.push("other");
        }
      }
      setAssets(assetsResult);

      setFoundationId(item?.foundation_id);

      form.setFieldsValue({
        room: item?.room?.name || item?.room_string,
        assets: assetsResult,
        assets_other: item?.assets.filter((x) => !defaultAssets.includes(x)),
        participant_count: item?.participant_count,
        dates: item?.dates?.map((date) => dayjs(date, "YYYY-MM-DD")) || [],
        times: [
          dayjs(item?.start_time, "HH:mm:ss"),
          dayjs(item?.end_time, "HH:mm:ss"),
        ],
        description: item?.description || "",
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const onCancel = () => {
    form.resetFields();
    setNewData({});
    setFoundationId("");
    setAssets([]);
    setItems([]);
    setOpen();
  };

  const onSubmit = async () => {
    const values = await form.validateFields();

    const isValid = editValidation({ newData });
    if (!isValid) return;

    if (newData.dates) {
      newData.dates = newData.dates.map((x) => dayjs(x).format("YYYY-MM-DD"));
    }
    if (newData.times) {
      newData.start_time = dayjs(newData.times[0]).format("HH:mm:ss");
      newData.end_time = dayjs(newData.times[1]).format("HH:mm:ss");
    }
    if (newData.assets || newData.assets_other) {
      // other ceklis dan ada onChange pada assets_other
      if (values.assets.includes("other") && newData.assets_other) {
        let result = values.assets.map((item) =>
          defaultAssets.includes(item) ? item : values.assets_other
        );

        result = [...new Set(result)];
        newData.assets = result;
      }
      // other ceklis dan tidak ada onChange  pada assets_other
      if (values.assets.includes("other") && values.assets_other) {
        let result = values.assets.map((item) =>
          defaultAssets.includes(item) ? item : values.assets_other
        );

        result = [...new Set(result)].flat();
        newData.assets = result;
      } else {
        const res = values.assets.filter((x) => x !== "other");
        newData.assets = res;
      }
    }

    try {
      setLoading(true);
      await axios.put(
        `${REACT_APP_HOST_API}/inventories/room-bookings/${item.id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      refetch();
      onCancel();
      message.success("Berhasil Menyimpan Data");
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <div>{error?.response?.data?.message}</div>,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setItems(dataRoom?.data);
    refetchRoom();
  }, [dataRoom, refetchRoom]);

  const [items, setItems] = useState([]);
  const inputRef = useRef(null);
  const [roomName, setRoomName] = useState("");

  const addItem = () => {
    if (!roomName) {
      setIsEmptyName(true);
      return;
    }
    try {
      form.setFieldValue("room", roomName);
      setIsEmptyName(false);
      message.success("Berhasil Menambah Ruangan");
      setRoomName("");
      setDropdownOpen(false);
    } catch (error) {
      alert(error);
    }
  };
  const handleTimeChange = (value) => {
    if (isOpenTime) {
      const timeConvert = value.map((item) =>
        dayjs(item, "HH:mm").format("HH:mm:ss")
      );
      setTimeValue(timeConvert);
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      className="top-5"
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Ruangan" name="room">
          <Select
            open={dropdownOpen}
            onDropdownVisibleChange={(open) => setDropdownOpen(open)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="pilih ruangan..."
            onChange={(e) => (newData["room"] = e)}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                    position: "relative",
                  }}
                >
                  <div className="w-[180px]">
                    <Input
                      placeholder="Masukan Nama Ruangan..."
                      ref={inputRef}
                      onChange={(e) => {
                        setRoomName(e.target.value);
                        newData["room"] = e.target.value;
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                      className={isEmptyName && "border border-[#ff4d4f]"}
                    />
                    {isEmptyName && (
                      <span className="text-[#ff4d4f] text-sm">
                        Please enter a room name
                      </span>
                    )}
                  </div>

                  <Button
                    style={{ position: "absolute", top: 0 }}
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Tambah Ruangan
                  </Button>
                </Space>
              </>
            )}
            options={items?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
        </Form.Item>

        <Form.Item label="Barang" name="assets">
          <Checkbox.Group
            onChange={(e) => {
              setAssets(e);
              newData["assets"] = e;
            }}
          >
            <Checkbox value="Laptop">Laptop</Checkbox>
            <Checkbox value="Proyektor">Proyektor</Checkbox>
            <Checkbox value="Kursi">Kursi</Checkbox>
            <Checkbox value="Meja">Meja</Checkbox>
            <Checkbox value="Rol Kabel">Rol Kabel</Checkbox>
            <Checkbox value="other">Other</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        {assets.includes("other") && (
          <Form.Item name="assets_other">
            <Input
              autoFocus
              onChange={(e) => (newData["assets_other"] = e.target.value)}
            />
          </Form.Item>
        )}

        <Form.Item label="Jumlah Peserta" name="participant_count">
          <InputNumber
            onChange={(e) => (newData["participant_count"] = e)}
            min={0}
            className="w-full"
          />
        </Form.Item>

        <div className="grid md:gap-5 md:grid-cols-2">
          <Form.Item label="Tanggal (Daftar Tanggal)" name="dates">
            <DatePicker
              onChange={(e) => (newData["dates"] = e)}
              multiple
              maxTagCount="responsive"
            />
          </Form.Item>

          <Form.Item label="Waktu (Dari-Sampai)" name="times">
            <TimePicker.RangePicker
              format="HH:mm"
              value={timeValue}
              onCalendarChange={handleTimeChange}
              onOpenChange={setIsOpenTime}
              onChange={(e) => {
                newData["times"] = e;
              }}
            />
          </Form.Item>
        </div>

        <Form.Item label="Deskripsi Keperluan" name="description">
          <Input.TextArea
            onChange={(e) => (newData["description"] = e.target.value)}
            rows={3}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
