import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useRoomBookingList = (foundationId = "", keyword = "") => {
  return useQuery(
    ["get-room-booking-list", foundationId, keyword],
    () =>
      GetList(
        `/inventories/room-bookings?mode=list&foundation_id=${foundationId}&keyword=${keyword}`
      ),
    {
      enabled: true,
    }
  );
};
