import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, message, Modal, Select, Upload } from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { editValidation } from "components/Helper/editValidation";
import { useEmployeeList } from "hooks/employee-hook/useEmployeeList";
import React, { useEffect, useState } from "react";

export default function EditReportingSarpras({
  open,
  setOpen,
  data: dataRecord,
  refetch,
}) {
  const [foundationId, setFoundationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [newData, setNewData] = useState({});

  // hardcode permission
  const roles = decryptCookies("role").split(",");
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const { id } = dataRecord;

  const { data: dataEmployee, refetch: refetchEmployee } = useEmployeeList(
    "",
    "",
    "",
    "",
    foundationId,
    "",
    ""
  );

  useEffect(() => {
    if (open) {
      setFoundationId(dataRecord?.foundation_id);
      setFileList([
        {
          uid: "-1",
          status: "done",
          url: dataRecord?.image,
          thumbUrl: dataRecord?.image,
        },
      ]);
      form.setFieldsValue({
        name: dataRecord?.name,
        description: dataRecord?.description,
        location: dataRecord?.location,
        employee_id: dataRecord?.employee_id,
        status: dataRecord?.status,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  // upload image
  const [deleteImages, setDeleteImages] = useState([]);

  const isImage = (file) => {
    return (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    );
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // Set the preview property if it's not already set
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    // Show preview modal
    Modal.info({
      title: file.name,
      content: (
        <img
          alt="preview"
          style={{ width: "100%" }}
          src={file.url || file.preview}
        />
      ),
    });
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    setNewData({ ...newData, image: null });
    setDeleteImages([...deleteImages, file?.name]);
    form.setFieldsValue({ image: null });
  };

  const beforeUpload = (file) => {
    if (!isImage(file)) {
      message.error("hanya bisa upload file gambar (.jpeg, .jpg, .png)");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("gambar ukurannya harus kurang dari 2MB!");
      return false;
    }
    if (!isImage(file) && fileList.length > 0) {
      setFileList([...fileList]);
      return false;
    }
    setFileList(isImage(file) ? [file] : []);
    return false;
  };
  const handleChangeImage = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const isLt2M = newFileList[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return;
      }
    }
    setFileList(newFileList);
    setNewData({ ...newData, image: newFileList[0]?.originFileObj || null });
  };

  const propsUpload = {
    onRemove: handleRemove,
    beforeUpload,
    fileList,
    onChange: handleChangeImage,
  };
  // end upload image

  useEffect(() => {
    if (foundationId) {
      refetchEmployee();
    }
    // eslint-disable-next-line
  }, [foundationId]);

  const onCancel = () => {
    form.resetFields();
    setFileList([]);
    setFoundationId("");
    setNewData({});
    setOpen(false);
  };

  const onSubmit = async () => {
    if (fileList.length === 0) {
      form.resetFields(["image"]);
    }

    const isValid = editValidation({ newData });
    if (!isValid) return;

    try {
      setLoading(true);

      const { data } = await axios.put(
        REACT_APP_HOST_API + `/inventories/reportings/${id}`,
        newData,
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success(data.message);
      refetch();
      onCancel();
    } catch (error) {
      Modal.error({
        title: "Gagal Menyimpan",
        content: <div>{error?.response?.data?.message}</div>,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="top-10"
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Nama Barang" name="name">
          <Input onChange={({ target: { value } }) => (newData.name = value)} />
        </Form.Item>

        <Form.Item label="Deskripsi Laporan" name="description">
          <Input.TextArea
            rows={3}
            onChange={({ target: { value } }) => (newData.description = value)}
          />
        </Form.Item>

        <Form.Item
          tooltip="cth: kelas XI lantai 1"
          label="Lokasi"
          name="location"
        >
          <Input
            onChange={({ target: { value } }) => (newData.location = value)}
          />
        </Form.Item>

        {roles.includes("super_admin") && (
          <Form.Item label="Pelapor" name="employee_id">
            <Select
              onChange={(e) => (newData.employee_id = e)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataEmployee?.data?.map((data) => (
                <Select.Option key={data.id} value={data.id}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="status" label="Status">
          <Select onChange={(e) => (newData.status = e)}>
            <Select.Option value="waiting">Waiting</Select.Option>
            <Select.Option value="received">Received</Select.Option>
            <Select.Option value="processed">Processed</Select.Option>
            <Select.Option value="done">Done</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="image"
          label="Gambar"
          rules={[
            {
              validator: (_, value) => {
                const file = value?.fileList[0];
                if (!file) {
                  return Promise.resolve();
                }
                if (!isImage(file)) {
                  return Promise.reject(
                    new Error("Please upload an image file")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            name="image"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={true}
            onPreview={handlePreview}
            {...propsUpload}
            disabled={loading}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                {fileList.length === 0 ? (
                  <span style={{ fontSize: "12px" }}>
                    Upload <br />
                    (max 2 mb)
                  </span>
                ) : (
                  "Ganti"
                )}
                {fileList.length === 0 ? "" : ""}
              </div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
