import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { BsDownload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import EditEmployee from "../edit/EditEmployee";
import UpdateRfid from "../rfid/UpdateRfid";
import { DeleteApi } from "api/DeleteApi";
import { useDivisionList } from "hooks/divison-hook/useDivisionList";
import { useEmployeePagination } from "hooks/employee-hook/useEmployeePagination";
import { useEmployeeTypeList } from "hooks/employee-hook/useEmployeeTypeList";
import { usePermission } from "hooks/usePermissions";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { decryptCookies } from "components/Helper/CookiesHelper";
import "./Employee.css";
import { useUnitPagination } from "hooks/unit-hook/useUnitPagination";
import { FilterOutlined } from "@ant-design/icons";

const disabled = { cursor: "not-allowed", color: "#ccc" };

const EmployeeTable = () => {
  const [dataId, setDataId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [divisionId, setDivisionId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [employeeTypeId, setEmployeeTypeId] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showRfid, setShowRfid] = useState(false);
  const [statusAnggota, setStatusAnggota] = useState("active");
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });
  const [showDetailFilter, setShowDetailFilter] = useState(false);
  const [filterBpjs, setFilterBpjs] = useState({
    showHasBpjsHealth: false,
    showNotHasBpjsHealth: false,
    showHasBpjsEmployment: false,
    showNotHasBpjsEmployment: false,
  });
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const isAdminFoundation = roles.some((x) => x === "admin_foundation");
  const isAdminHrd = roles.some((x) => x === "admin_hrd");
  const [form] = Form.useForm();

  let navigate = useNavigate();

  // fetch employee pagination
  const {
    data: dataEmployee,
    isLoading,
    isFetching,
    refetch,
  } = useEmployeePagination({
    dataTable,
    keyword,
    divisionId,
    employeeTypeId,
    isActive: statusAnggota,
    foundationId,
    filterBpjs,
    unitId,
  });

  // fetch devision list
  const { data: dataDivisi } = useDivisionList(unitId);

  // fetch unit pagination
  const {
    data: dataUnit,
    isLoading: isLoadingUnit,
    isFetching: isFetchingUnit,
  } = useUnitPagination(
    {
      current_page: 1,
      per_page: 100000,
      total: 0,
    },
    keyword,
    foundationId
  );

  // fetch employee type
  const { data: dataEmployeeType, refetch: fetchEmployeeType } =
    useEmployeeTypeList();

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // fetch permission
  const { data: dataPermission } = usePermission("mdlHRD", "mnuEmployee");

  const btnAdd = dataPermission?.find((x) => x.id === "btnAddEmployee");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditEmployee");
  const btnDelete = dataPermission?.find((x) => x.id === "btnDeleteEmployee");
  const btnDetail = dataPermission?.find((x) => x.id === "btnDetailEmployee");
  const btnRfid = dataPermission?.find((x) => x.id === "btnUpdateRFID");
  const btnDownload = dataPermission?.find(
    (x) => x.id === "btnDownloadEmployee"
  );

  useEffect(() => {
    fetchEmployeeType();
  }, [fetchEmployeeType]);

  const onUpdate = () => {
    setShowEdit(false);
    setShowRfid(false);
    refetch();
  };

  const onCancel = () => {
    setShowEdit(false);
    setShowRfid(false);
  };

  const toggleDetailFilter = () => {
    setShowDetailFilter(!showDetailFilter);
  };

  const unitTitle = {
    lead: "Ketua",
    vice: "Wakil",
    member: "Anggota",
  };

  const dataToExport = dataEmployee?.data?.data?.map((x) => {
    return {
      Nama: x.name,
      Email: x.user?.email,
      NIK: x.nik,
      NIP: x.nip,
      rfid: x.rfid,
      "Jenis Kelamin": x.gender && x.gender === "L" ? "Laki-laki" : "Perempuan",
      "Tempat Lahir": x.birth_city,
      "Tanggal Lahir": x.birth_day,
      "Tanggal Masuk": x.date_in,
      "Tanggal Keluar": x.date_out,
      "Jatah Cuti": x.sisa_jatah_cuti,
      "Tipe Karyawan": x.employee_type_id,
      status: x.status,
      Yayasan: x.foundation?.name,
      Divisi: x.divisions
        ?.map((divisi) => {
          return `${divisi.title} | ${divisi.divsion?.name}`;
        })
        .join(", "),
      Unit: x.employeeUnits
        .map((unit) => {
          return `${unitTitle[unit.title]} | ${unit.unit.name}`;
        })
        .join(", "),
      "Memiliki BPJS Kesehatan": x.bpjs_health ? "Ya" : "Tidak",
      "Memiliki BPJS Ketenagakerjaan": x.bpjs_employment ? "Ya" : "Tidak",
      Alamat: x.address,
      Kecamatan: x.kecamatan?.nama,
      Kelurahan: x.kelurahan?.nama,
      Kota: x.kota?.nama,
      Provinsi: x.provinsi?.nama,
    };
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "data_karyawan.xlsx");
  };

  function getUnitAndTitle(employeeUnits) {
    return employeeUnits.map((unit) => {
      return (
        <Tag color="green" key={unit.unit_id}>
          {unitTitle[unit.title]} | {unit.unit.name}
        </Tag>
      );
    });
  }

  const dataSource = dataEmployee?.data?.data
    .slice(0, dataTable.per_page)
    .map((employee, index) => {
      return {
        ...employee,
        index: index + 1,
        key: employee.id,
        status: employee.status
          ? employee.employee_type_id + " - " + employee.status
          : employee.employee_type_id,
        unitName: getUnitAndTitle(employee.employeeUnits),
        foundation: employee.foundation?.name,
        email: employee?.user?.email,
      };
    });

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width: window.innerWidth < 768 ? 40 : 60,
      fixed: "left",
    },
    {
      title: "Nama",
      dataIndex: "name",
      fixed: "left",
      width: window.innerWidth < 768 ? 150 : 180,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: window.innerWidth < 768 ? 150 : 180,
    },
    {
      title: "Yayasan",
      dataIndex: "foundation",
      hidden: !isSuperAdmin,
      width: 120,
    },
    { title: "Unit", dataIndex: "unitName", width: 120 },
    {
      title: "NIK",
      dataIndex: "nik",
      width: 120,
      hidden: isAdminFoundation || isAdminHrd || isSuperAdmin ? false : true,
    },
    {
      title: "NIP",
      dataIndex: "nip",
      hidden: isAdminFoundation || isAdminHrd || isSuperAdmin ? false : true,
    },
    { title: "Status", dataIndex: "status", width: 130 },
    {
      title: "Sisa Jatah Cuti",
      dataIndex: "sisa_jatah_cuti",
      width: 120,
      // hidden: isAdminFoundation || isAdminHrd || isSuperAdmin ? false : true,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      align: "center",
      hidden: btnEdit || btnRfid || btnDetail || btnDelete ? false : true,
      render: (id) => (
        <Space>
          <Popconfirm
            title="Yakin ingin menghapus data?"
            okText="Delete"
            cancelText="Batal"
            style={{ cursor: "pointer" }}
            onConfirm={() =>
              DeleteApi({
                url: "/employee/",
                dataId,
                refetch,
              })
            }
            disabled={btnDelete && btnDelete.type === "disabled"}
          >
            <Tag
              color="red"
              hidden={!btnDelete}
              style={
                btnDelete?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDelete?.type === "disabled") {
                  return;
                } else {
                  setDataId(id);
                }
              }}
            >
              Hapus
            </Tag>
          </Popconfirm>
          <Tag
            color="orange"
            hidden={!btnEdit}
            style={
              btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnEdit?.type === "disabled") {
                return;
              } else {
                setDataId(id);
                setShowEdit(true);
              }
            }}
          >
            Ubah
          </Tag>
          <Tag
            color="orange"
            hidden={!btnRfid}
            style={
              btnRfid?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
            onClick={() => {
              if (btnRfid?.type === "disabled") {
                return;
              } else {
                setDataId(id);
                setShowRfid(true);
              }
            }}
          >
            Rfid
          </Tag>
          <Tag
            color="blue"
            hidden={!btnDetail}
            disabled={btnDetail?.type === "disabled"}
            onClick={() =>
              btnDetail?.type === "disabled"
                ? null
                : navigate("/hrd/employee/" + id)
            }
            style={
              btnDetail?.type === "disabled" ? disabled : { cursor: "pointer" }
            }
          >
            Detail
          </Tag>
        </Space>
      ),
    },
  ].filter((x) => x.hidden !== true);

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    defaultPageSize: dataTable.per_page,
    total: dataEmployee?.data?.meta.total,
    pageSizeOptions: [
      10,
      20,
      50,
      100,
      dataEmployee?.data?.meta.total > 200
        ? dataEmployee?.data?.meta.total
        : 200,
    ],
    showSizeChanger: true,
    onChange: (curr, size) => {
      setDataTable((value) => {
        return {
          ...value,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return (
    <>
      <div className="table-header">
        <h1>Daftar Karyawan</h1>
        <Space size="middle">
          {btnDownload?.type === "show" && (
            <Tooltip title="Export">
              <BsDownload
                onClick={handleExport}
                style={{ fontSize: "1.7em", color: "#666", cursor: "pointer" }}
              />
            </Tooltip>
          )}
          <Button
            type="primary"
            hidden={!btnAdd}
            onClick={() => navigate("create")}
            disabled={btnAdd && btnAdd.type === "disabled"}
          >
            Tambah
          </Button>
        </Space>
      </div>
      <Form form={form} layout="vertical" className="form-filter">
        <div className="filter-table">
          <Form.Item>
            <Tooltip title="nama">
              <Input
                value={keyword}
                onChange={(param) => setKeyword(param.target.value)}
                placeholder="Search"
              />
            </Tooltip>
          </Form.Item>

          {isSuperAdmin && (
            <Form.Item>
              <Tooltip title="Yayasan">
                <Select
                  placeholder="Pilih Yayasan"
                  style={{ minWidth: 100 }}
                  onChange={(x) => {
                    setUnitId("");
                    setFoundationId(x);
                    form.resetFields(["unitId"]);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option className="select-option-foundation" value="">
                    ALL
                  </Select.Option>
                  {dataFoundation?.data?.data?.map((x) => (
                    <Select.Option
                      key={x.id}
                      value={x.id}
                      className="select-option-foundation"
                    >
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>
          )}
          <Form.Item name="unitId">
            <Tooltip title="Unit">
              <Select
                placeholder="Unit"
                style={{ width: "100%" }}
                onChange={(x) => {
                  setUnitId(x);
                  setDivisionId("");
                  form.resetFields(["divisionId"]);
                }}
                disabled={isLoadingUnit || isFetchingUnit}
              >
                <Select.Option value="">ALL</Select.Option>
                {dataUnit?.data?.data?.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
          <Form.Item>
            <Button
              icon={<FilterOutlined />}
              onClick={toggleDetailFilter}
              style={{
                maxWidth: "100px",
              }}
            >
              Filters
            </Button>
          </Form.Item>
        </div>

        {showDetailFilter && (
          <div className="filter-table">
            <Form.Item name="divisionId">
              <Tooltip title="Divisi">
                <Select
                  placeholder="Divisi"
                  style={{ width: "100%" }}
                  onChange={(x) => setDivisionId(x)}
                >
                  <Select.Option value="">ALL</Select.Option>
                  {dataDivisi?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <Tooltip title="Tipe">
                <Select
                  placeholder="Tipe"
                  style={{ width: "100%" }}
                  onChange={(x) => setEmployeeTypeId(x)}
                >
                  <Select.Option value="">ALL</Select.Option>
                  {dataEmployeeType?.data?.data?.map((x) => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.id}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <Tooltip title="Status">
                <Select
                  placeholder="Status"
                  style={{ width: "100%" }}
                  onChange={(x) => setStatusAnggota(x)}
                >
                  <Select.Option value="">ALL</Select.Option>
                  <Select.Option key="active" value="active">
                    Aktif
                  </Select.Option>
                  <Select.Option key="not_active" value="not_active">
                    Tidak Aktif
                  </Select.Option>
                </Select>
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <div className="filter-bpjs">
                <Tooltip title="BPJS">
                  <Select
                    placeholder="Bpjs"
                    mode="multiple"
                    style={{ width: "100%" }}
                    maxTagCount="responsive"
                    onChange={(x) => {
                      console.log(x);
                      setFilterBpjs({
                        showHasBpjsHealth: x.includes("showHasBpjsHealth"),
                        showNotHasBpjsHealth: x.includes(
                          "showNotHasBpjsHealth"
                        ),
                        showHasBpjsEmployment: x.includes(
                          "showHasBpjsEmployment"
                        ),
                        showNotHasBpjsEmployment: x.includes(
                          "showNotHasBpjsEmployment"
                        ),
                      });
                    }}
                  >
                    <Select.Option
                      value="showHasBpjsHealth"
                      className="select-option-foundation"
                    >
                      Memiliki Bpjs Kesehatan
                    </Select.Option>
                    <Select.Option
                      className="select-option-foundation"
                      value="showNotHasBpjsHealth"
                    >
                      Tidak Memiliki Bpjs Kesehatan
                    </Select.Option>
                    <Select.Option
                      className="select-option-foundation"
                      value="showHasBpjsEmployment"
                    >
                      Memiliki Bpjs Ketenagakerjaan
                    </Select.Option>
                    <Select.Option
                      className="select-option-foundation"
                      value="showNotHasBpjsEmployment"
                    >
                      Tidak Memiliki Bpjs Ketenagakerjaan
                    </Select.Option>
                  </Select>
                </Tooltip>
              </div>
            </Form.Item>
          </div>
        )}
      </Form>
      <Table
        size="small"
        tableLayout="auto"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={dataSource}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 1300 }
            : { y: "55vh", x: 1300 }
        }
      />
      <EditEmployee
        id={dataId}
        show={showEdit}
        onUpdate={onUpdate}
        onCancel={onCancel}
      />
      <UpdateRfid
        id={dataId}
        show={showRfid}
        onUpdateRfid={onUpdate}
        onCancel={onCancel}
      />
    </>
  );
};

export default EmployeeTable;
