import { Image, Modal } from "antd";
import React from "react";

export default function DetailReportingSarpras({ open, setOpen, data }) {
  const dataStatus = [
    { value: "waiting", label: "Menunggu" },
    { value: "received", label: "Diterima" },
    { value: "processed", label: "Diproses" },
    { value: "done", label: "Selesai" },
  ];

  return (
    <Modal open={open} onCancel={setOpen} footer={false}>
      <table>
        <tbody>
          <tr>
            <td>Barang</td>
            <td>: {data?.name}</td>
          </tr>

          <tr>
            <td>Deskripsi</td>
            <td className="break-words max-w-[280px]">: {data?.description}</td>
          </tr>

          <tr>
            <td>Lokasi</td>
            <td>: {data?.location}</td>
          </tr>

          <tr>
            <td>Tanggal Pelaporan</td>
            <td>: {data?.report_date_only}</td>
          </tr>

          <tr>
            <td>Pelapor</td>
            <td>: {data?.employee?.name}</td>
          </tr>

          <tr>
            <td>Status</td>
            <td>: {dataStatus.find((x) => x.value === data?.status)?.label}</td>
          </tr>

          <tr>
            <td>Gambar</td>
            <td>
              <Image width={200} src={data?.image} />
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}
