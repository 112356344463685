import { SearchOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  Tooltip,
  theme,
  Dropdown,
} from "antd";
import { DeleteApi } from "api/DeleteApi";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import dayjs from "dayjs";
import { useReportingSarprasPagination } from "hooks/sarpras-hook/reporting-sarpras/useReportingSarprasPagination";
import { usePermission } from "hooks/usePermissions";
import React, { useState } from "react";
import DetailReportingSarpras from "../detail/DetailReportingSarpras";
import useResetPage from "components/Helper/useResetPage";
import AddReportingSarpras from "../create/AddReportingSarpras";
import EditReportingSarpras from "../edit/EditReportingSarpras";
import { useMediaQuery } from "react-responsive";
import usePagination from "components/Helper/usePagination";

const startOfMonth = dayjs().startOf("month");
const endOfMonth = dayjs().endOf("month");
const formatDate = "YYYY-MM-DD";

export default function ReportingSarpras() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [img, setImg] = useState("");
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(
    dayjs(startOfMonth).format(formatDate)
  );
  const [toDate, setToDate] = useState(dayjs(endOfMonth).format(formatDate));
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 20,
    total: 0,
  });

  const isMobile = useMediaQuery({
    maxWidth: 768,
  });

  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  //hardcode permission
  const roles = decryptCookies("role").split(",");
  const disabled = { cursor: "not-allowed", color: "#ccc" };
  const dataStatus = [
    { value: "waiting", label: "Menunggu", color: "red" },
    { value: "received", label: "Diterima", color: "orange" },
    { value: "processed", label: "Diproses", color: "blue" },
    { value: "done", label: "Selesai", color: "green" },
  ];

  const { data, refetch, isFetching } = useReportingSarprasPagination(
    dataTable,
    keyword,
    foundationId,
    status,
    fromDate,
    toDate
  );

  // fetch permission
  const { data: dataPermission } = usePermission(
    "mdlInventory",
    "mnuFacilityReporting"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find(
    (x) => x.id === "btnAddFacilityReporting"
  );
  const btnEdit = dataPermission?.find(
    (x) => x.id === "btnEditFacilityReporting"
  );
  const btnEditStatus = dataPermission?.find(
    (x) => x.id === "btnEditStatusFacilityReporting"
  );
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteFacilityReporting"
  );
  const btnDetail = dataPermission?.find(
    (x) => x.id === "btnDetailFacilityReporting"
  );

  const onUpdateStatus = async (value, id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_HOST_API}/inventories/reportings/${id}`,
        {
          status: value,
        },
        {
          headers: {
            Authorization: "Bearer " + decryptCookies("token"),
          },
        }
      );
      refetch();
      message.success("Status Berhasil Diubah");
    } catch (error) {
      Modal.error({
        title: "Gagal Mengubah Status",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    }
  };

  const items = dataStatus.map((x) => ({
    key: x.value,
    label: (
      <Badge
        onClick={() => {
          if (btnEdit?.type === "disabled") return;
          else {
            onUpdateStatus(x.value, record.id);
          }
        }}
        color={x.color}
        text={x.label}
      />
    ),
  }));

  const columns = [
    {
      title: "Barang",
      dataIndex: "name",
      fixed: isMobile ? "left" : "",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      render: (desc) => {
        if (desc.length > 50)
          return (
            <div>
              {desc.slice(0, 50)}...{" "}
              <Popover
                content={
                  <div className="w-[200px] max-h-[200px] overflow-y-auto">
                    {<p className="break-words">{desc}</p>}
                  </div>
                }
                title="Deskripsi"
              >
                <span className="font-bold text-blue-500 cursor-pointer">
                  Detail
                </span>
              </Popover>
            </div>
          );
        return desc;
      },
    },
    {
      title: "Lokasi",
      dataIndex: "location",
    },
    {
      title: "Tanggal Pelaporan",
      dataIndex: "report_date",
      render: (reportDate) => dayjs(reportDate).format("DD-MM-YYYY"),
    },
    {
      title: "Pelapor",
      dataIndex: "employee",
      render: (reporter) => reporter?.name,
    },
    {
      title: "Foto",
      dataIndex: "image",
      render: (image) =>
        image ? (
          <div
            className="font-bold text-blue-500 cursor-pointer"
            onClick={() => {
              setVisible(true);
              setImg(image);
            }}
          >
            Lihat Gambar
          </div>
        ) : (
          <span>-</span>
        ),
    },

    {
      title: "Status",
      dataIndex: "status",
      visible: btnEditStatus ? true : false,
      render: (status, record) => {
        const matchedStatus = dataStatus.find((x) => x.value === status);

        if (matchedStatus) {
          return (
            <Dropdown
              menu={{
                items,
              }}
              disabled={btnEditStatus?.type === "disabled"}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  {React.cloneElement(menu, {
                    style: menuStyle,
                  })}
                </div>
              )}
            >
              <Tag
                hidden={!btnEditStatus}
                onClick={() => {
                  if (btnEditStatus?.type === "disabled") return;
                  else {
                    setRecord(record);
                  }
                }}
                style={
                  btnEditStatus?.type === "disabled"
                    ? { cursor: "default" }
                    : { cursor: "pointer" }
                }
                color={matchedStatus.color}
              >
                {matchedStatus.label}
              </Tag>
            </Dropdown>
          );
        }

        return;
      },
    },

    {
      title: "Aksi",
      dataIndex: "id",
      width: 200,
      visible: btnDelete || btnEdit || btnDetail ? true : false,
      render: (id, record) => {
        return (
          <>
            <Popconfirm
              disabled={btnDelete?.type === "disabled"}
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: "/inventories/reportings/",
                  dataId: id,
                  refetch,
                })
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  setRecord(record);
                  setOpenEdit(true);
                }
              }}
            >
              Ubah
            </Tag>

            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                else {
                  setRecord(record);
                  handleOpenDetail();
                }
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ].filter((item) => item.visible !== false);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
      };
    });

  const pagination = usePagination({
    dataTable,
    setDataTable,
    data,
  });

  const handleDate = (date, setter) => {
    if (date) {
      const formattedDate = dayjs(date).format(formatDate);
      setter(formattedDate);
    } else {
      setter("");
    }
  };

  const handleOpenDetail = () => {
    setOpenDetail(!openDetail);
  };

  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
  };
  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  useResetPage(setDataTable, [keyword, foundationId, status, fromDate, toDate]);

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Pelaporan</h1>

        <Button
          type="primary"
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          onClick={() => {
            if (btnAdd?.type === "disabled") return;
            else {
              setOpenAdd(true);
            }
          }}
        >
          Tambah
        </Button>
      </div>
      <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-5">
        <Tooltip title="Cari">
          <Input
            prefix={<SearchOutlined />}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Cari Pelapor/Nama Barang/Lokasi"
          />
        </Tooltip>

        {roles.includes("super_admin") && (
          <FilterFoundation setFoundationId={setFoundationId} />
        )}

        <Tooltip title="Tanggal Awal">
          <DatePicker
            defaultValue={startOfMonth}
            format={formatDate}
            onChange={(e) => handleDate(e, setFromDate)}
          />
        </Tooltip>

        <Tooltip title="Tanggal Akhir">
          <DatePicker
            defaultValue={endOfMonth}
            onChange={(e) => handleDate(e, setToDate)}
          />
        </Tooltip>

        <Tooltip title="Status">
          <Select onChange={setStatus}>
            <Select.Option value="">Semua</Select.Option>
            {dataStatus.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </div>
      <Table
        loading={isFetching}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        size="small"
        scroll={
          window.innerHeight < 760
            ? {
                y: "50vh",
                x: 900,
              }
            : {
                y: "55vh",
                x: 900,
              }
        }
      />
      <Image
        width={200}
        style={{
          display: "none",
        }}
        src={img}
        preview={{
          visible,
          scaleStep: 0.5,
          src: img,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      <AddReportingSarpras
        open={openAdd}
        setOpen={handleOpenAdd}
        refetch={refetch}
      />
      <EditReportingSarpras
        open={openEdit}
        setOpen={handleOpenEdit}
        refetch={refetch}
        data={record}
      />
      <DetailReportingSarpras
        open={openDetail}
        setOpen={handleOpenDetail}
        data={record}
      />
    </div>
  );
}
