import { GetPagination } from "api/GetPagination";
import { useQuery } from "react-query";

export const useEmployeePagination = ({
  dataTable,
  keyword,
  divisionId,
  employeeTypeId,
  isActive,
  foundationId,
  filterBpjs,
  unitId = "",
}) => {
  return useQuery(
    [
      "get-employee-pagination",
      dataTable.current_page,
      dataTable.per_page,
      keyword,
      divisionId,
      employeeTypeId,
      isActive,
      foundationId,
      filterBpjs,
      unitId,
    ],
    // const [filterBpjs, setFilterBpjs] = useState({
    //   showHasBpjsHealth: false,
    //   showNotHasBpjsHealth: false,
    //   showHasBpjsEmployment: false,
    //   showNotHasBpjsEmployment: false,
    // });
    () =>
      GetPagination(
        `/employee/?page=${dataTable.current_page}&limit=${dataTable.per_page}&keyword=${keyword}&employeeTypeId=${employeeTypeId}&divisionId=${divisionId}&isActive=${isActive}&foundationId=${foundationId}&showHasBpjsHealth=${filterBpjs.showHasBpjsHealth}&showNotHasBpjsHealth=${filterBpjs.showNotHasBpjsHealth}&showHasBpjsEmployment=${filterBpjs.showHasBpjsEmployment}&showNotHasBpjsEmployment=${filterBpjs.showNotHasBpjsEmployment}&unitIdParam=${unitId || ""}`
      )
  );
};
