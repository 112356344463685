const usePagination = ({
  dataTable,
  setDataTable,
  data,
  pageSize = [20, 50, 100],
}) => {
  const maxSize = Math.max(...pageSize);
  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [
      ...pageSize,
      ...(data?.data.meta.total > maxSize ? [data?.data.meta.total] : []),
    ],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  return pagination;
};

export default usePagination;
