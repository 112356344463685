import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { decryptCookies } from "components/Helper/CookiesHelper";
import FilterFoundation from "components/UI/FilterFoundation";
import React, { useState } from "react";
import CalendarRoomBooking from "./CalendarRoomBooking";
import AddRoomBooking from "../create/AddRoomBooking";
import { useRoomBookingList } from "hooks/sarpras-hook/room-booking/useRoomBookingList";
import { useRoomBookingPagination } from "hooks/sarpras-hook/room-booking/useRoomBookingPagination";
import useResetPage from "components/Helper/useResetPage";
import { DeleteApi } from "api/DeleteApi";
import { usePermission } from "hooks/usePermissions";
import DetailRoomBooking from "../detail/DetailRoomBooking";
import EditRoomBooking from "../edit/EditRoomBooking";
import dayjs from "dayjs";

const formatDate = "YYYY-MM-DD";
export default function RoomBooking() {
  const [record, setRecord] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const [mode, setMode] = useState("calendar");
  const [openDetail, setOpenDetail] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  });

  const roles = decryptCookies("role").split(",");
  const disabled = { cursor: "not-allowed", color: "#ccc" };

  const { data: dataRoomBookingList, refetch: refetchRoomBookingList } =
    useRoomBookingList(foundationId);
  const { data, refetch, isFetching } = useRoomBookingPagination(
    dataTable,
    keyword,
    foundationId,
    fromDate,
    toDate
  );

  // fetch permission
  const { data: dataPermission } = usePermission(
    "mdlInventory",
    "mnuRoomBooking"
  );

  // Buttons Permission
  const btnAdd = dataPermission?.find((x) => x.id === "btnAddRoomBooking");
  const btnEdit = dataPermission?.find((x) => x.id === "btnEditRoomBooking");
  const btnDelete = dataPermission?.find(
    (x) => x.id === "btnDeleteRoomBooking"
  );
  const btnDetail = dataPermission?.find(
    (x) => x.id === "btnDetailRoomBooking"
  );

  const columns = [
    {
      title: "Ruangan",
      dataIndex: "room",
      render: (room, record) => {
        return <div>{room?.name ? room?.name : record?.room_string}</div>;
      },
    },
    {
      title: "Barang",
      dataIndex: "assets",
      render: (assets) => assets?.join(", "),
    },
    {
      title: "Tanggal Peminjaman",
      dataIndex: "dates",
      render: (dates) => {
        dates = dates?.join(", ");
        if (dates.length > 50)
          return (
            <div>
              {dates.slice(0, 50)}...{" "}
              <Popover
                content={
                  <div className="w-[200px] max-h-[200px] overflow-y-auto">
                    {<p className="break-words">{dates}</p>}
                  </div>
                }
                title="Tanggal"
              >
                <span className="font-bold text-blue-500 cursor-pointer">
                  Detail
                </span>
              </Popover>
            </div>
          );
        return dates;
      },
    },
    {
      title: "Waktu Peminjaman",
      dataIndex: "id",
      render: (id, record) => {
        return `${record?.start_time} - ${record?.end_time}`;
      },
    },
    {
      title: "Peminjam",
      dataIndex: "employee",
      render: (employee) => employee?.name,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      width: 200,
      visible: btnDelete || btnEdit || btnDetail ? true : false,
      render: (id, record) => {
        return (
          <>
            <Popconfirm
              disabled={btnDelete?.type === "disabled"}
              title="Yakin ingin menghapus ?"
              okText="Hapus"
              cancelText="Batal"
              onConfirm={() =>
                DeleteApi({
                  url: "/inventories/room-bookings/",
                  dataId: id,
                  refetch,
                })
              }
            >
              <Tag
                color="magenta"
                hidden={!btnDelete}
                style={
                  btnDelete?.type === "disabled"
                    ? disabled
                    : { cursor: "pointer" }
                }
              >
                Hapus
              </Tag>
            </Popconfirm>

            <Tag
              color="orange"
              hidden={!btnEdit}
              style={
                btnEdit?.type === "disabled" ? disabled : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnEdit?.type === "disabled") return;
                else {
                  setRecord(record);
                  setOpenEdit(true);
                }
              }}
            >
              Ubah
            </Tag>

            <Tag
              color="blue"
              hidden={!btnDetail}
              style={
                btnDetail?.type === "disabled"
                  ? disabled
                  : { cursor: "pointer" }
              }
              onClick={() => {
                if (btnDetail?.type === "disabled") return;
                else {
                  setRecord(record);
                  handleOpenDetail();
                }
              }}
            >
              Detail
            </Tag>
          </>
        );
      },
    },
  ].filter((item) => item.visible !== false);

  const dataSource = data?.data.data
    .slice(0, dataTable.per_page)
    .map((data, index) => {
      return {
        ...data,
        key: data.id,
        index: index + 1,
      };
    });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data.meta.total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (curr, size) => {
      setDataTable((prev) => {
        return {
          ...prev,
          current_page: curr,
          per_page: size,
        };
      });
    },
  };

  useResetPage(setDataTable, [keyword, foundationId]);

  const handleDate = (date, setter) => {
    if (date) {
      const formattedDate = dayjs(date).format(formatDate);
      setter(formattedDate);
    } else {
      setter("");
    }
  };
  const handleOpenAdd = () => {
    setOpenAdd(!openAdd);
  };
  const handleOpenDetail = () => {
    setOpenDetail(!openDetail);
  };
  const handleRefetch = () => {
    refetch();
    refetchRoomBookingList();
  };
  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <div>
      <div className="table-header">
        <h1>Daftar Pemakaian Ruangan</h1>

        <Button
          hidden={!btnAdd}
          disabled={btnAdd?.type === "disabled"}
          type="primary"
          onClick={() => (btnAdd?.type === "disabled" ? null : handleOpenAdd())}
        >
          Tambah
        </Button>
      </div>

      {/* hardcode permission */}
      {(roles.includes("super_admin") || roles.includes("admin_inventory")) && (
        <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-5">
          {mode === "list" && (
            <>
              <Tooltip title="Cari">
                <Input
                  prefix={<SearchOutlined />}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="Cari Ruangan..."
                />
              </Tooltip>

              {/* hardcode permission */}
              {roles.includes("super_admin") && (
                <FilterFoundation setFoundationId={setFoundationId} />
              )}

              <Tooltip title="Tanggal Awal">
                <DatePicker onChange={(e) => handleDate(e, setFromDate)} />
              </Tooltip>

              <Tooltip title="Tanggal Akhir">
                <DatePicker onChange={(e) => handleDate(e, setToDate)} />
              </Tooltip>
            </>
          )}

          <Radio.Group
            buttonStyle="solid"
            value={mode}
            onChange={(e) => {
              setMode(e.target.value);
            }}
          >
            <Radio.Button value="calendar">Kalender </Radio.Button>
            <Radio.Button value="list">List</Radio.Button>
          </Radio.Group>
        </div>
      )}

      {mode === "list" ? (
        <Table
          loading={isFetching}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          size="small"
          scroll={
            window.innerHeight < 760
              ? {
                  y: "50vh",
                  x: 900,
                }
              : {
                  y: "55vh",
                  x: 900,
                }
          }
        />
      ) : (
        <CalendarRoomBooking data={dataRoomBookingList} />
      )}

      <AddRoomBooking
        open={openAdd}
        setOpen={handleOpenAdd}
        refetch={handleRefetch}
      />
      <DetailRoomBooking
        open={openDetail}
        setOpen={handleOpenDetail}
        item={record}
      />
      <EditRoomBooking
        open={openEdit}
        setOpen={handleOpenEdit}
        item={record}
        refetch={handleRefetch}
      />
    </div>
  );
}
