import { GetList } from "api/GetList";
import { useQuery } from "react-query";

export const useRoomList = (foundationId = "", keyword = "") => {
  return useQuery(
    ["get-room-list", foundationId, keyword],
    () =>
      GetList(
        `/inventories/rooms?mode=list&foundation_id=${foundationId}&keyword=${keyword}`
      ),
    {
      enabled: true,
    }
  );
};
